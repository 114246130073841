<template>
    <div :class="['text-start w-100', layoutPaddingClass, 'bg-crc-search-module']">
    <h3 class="component-title text-white mb-4 mt-2">{{ title }}</h3>
    <div :class="['d-flex', 'search-bar-wrapper', justifyContentClass, 'w-100', layoutClass]">

        <div :class="['dropdowns', layoutClass === 'flex-column' ? 'd-flex flex-column' : 'd-flex flex-wrap w-100', 'my-3 search-field-wrapper']">
          <div class="custom-select-wrapper bg-white mx-1 p-1">
            <multiselect
                ref="multiselectBrandRef"
                v-model="selectedBrand"
                :options="brands"
                :searchable="true"
                deselectLabel="Klik om te verwijderen"
                selectLabel="Klik om te selecteren"
                selectedLabel="Geselecteerd"
                :close-on-select="true"
                :multiple="true"
                placeholder="Kies merken"
                :internal-search="false"
                :hide-selected="true"
                :loading="isLoading"
                :options-limit="300"
                :limit-text="limitText"
                :limit="3"
                id="ajax"
                open-direction="bottom"
                @search-change="asyncFind"
                :style="{ width: '100%' }">
                <template v-slot:noResult>
                  We hebben dit merk niet kunnen vinden.
                </template>
            </multiselect>

          </div>
          <div class="custom-select-wrapper bg-white mx-1 p-1">
            <multiselect
                ref="multiselectModelRef"
                v-model="selectedModel"
                :options="models"
                :multiple="true"
                deselectLabel="Klik om te verwijderen"
                selectLabel="Klik om te selecteren"
                selectedLabel="Geselecteerd"
                :maxHeight= "240"
                :close-on-select="true"
                :disabled="!selectedBrand.length"
                :placeholder="selectedBrand.length ? 'Selecteer model(len)' : 'Kies eerst een merk'">
              <template v-slot:noResult>
                We hebben dit model niet kunnen vinden.
              </template>
            </multiselect>

          </div>
          <div class="custom-select-wrapper bg-white mx-1 p-1">
            <multiselect
                ref="multiselectFuelRef"
                v-model="selectedFuelType"
                :options="fuelTypes"
                :close-on-select="true"
                :searchable="false"
                deselectLabel="Klik om te verwijderen"
                selectLabel="Klik om te selecteren"
                selectedLabel="Geselecteerd"
                :multiple="true"
                placeholder="Selecteer brandstoftype(s)">
              <template v-slot:tag="{ option, remove }">
                  <span class="multiselect__tag">
                    {{ translateOption(option) }}
                    <i class="multiselect__tag-icon" @mousedown.prevent="event => { event.preventDefault(); event.stopPropagation(); remove(option); }" ></i>
                  </span>
              </template>
                <template v-slot:option="{ option }">
                  {{ translateOption(option) }}

                </template>
            </multiselect>

          </div>
          <div v-if="validation.error" class="alert alert-primary d-flex align-items-center" role="alert">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2" viewBox="0 0 16 16" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            <span style="margin-left: 10px;">{{ validation.message }}</span>
          </div>
          <button class="btn-custom-view my-3 px-4 border-0 mx-1 text-white" @click="applyFiltersAndNavigate">BEKIJK {{ totalCars }} RESULTATEN</button>
      </div>

      <div v-if="loading">Loading...</div>
    </div>
  </div>
</template>



<script>
import {computed, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue';
import Multiselect from 'vue-multiselect'
import axios from 'axios'
import 'vue-multiselect/dist/vue-multiselect.css';

export default {
  components: {
    Multiselect
  },
  setup() {
    const allCars = ref([]);
    const allModels = ref([]);
    const models = ref([]);
    const brands = ref([]);
    const allBrands = ref([]);
    const fuelTypes = ref([]);
    const allFuelTypes = ref([]);
    const selectedBrand = ref([]);
    const selectedModel = ref([]);
    const selectedFuelType = ref([]);
    const carsToView = ref([]);
    const loading = ref(false);
    const validation = reactive({ error: null, message: '' });
    const windowWidth = ref(window.innerWidth);
    const title = ref('Snel zoeken');
    const isLoading = ref(false);

    const translateDict = ref({
      "B": "Benzine",
      "D": "Diesel",
      "B,E": "Benzine & Elektrisch",
      "D,E": "Diesel & Elektrisch",
      "E": "Elektrisch",
    });
    const multiselectModelRef = ref(null);
    const multiselectFuelRef = ref(null);
    const multiselectBrandRef = ref(null);


    const layoutClass = computed(() => {
      return windowWidth.value <= 768 ? 'flex-column' : (window.qsmLayout === 'column' ? 'flex-column' : 'flex-row');
    });

    const layoutPaddingClass = computed(() => {
      return window.qsmLayout === 'column' ? 'px-4 py-4 px-md-5 py-md-5 layout-vertical' : 'py-2 px-5 layout-horizontal';
    });

    const justifyContentClass = computed(() => {
      return window.layout === 'column' ? 'justify-content-md-center' : 'justify-content-md-start';
    });

    const totalCars = computed(() => carsToView.value.length);

    const limitText  = function (count) {
      return `en ${count} andere merken`
    };
    const asyncFind = async function (query) {
      isLoading.value = true;

      // Update de landen en zet isLoading naar false
      brands.value = await ajaxFindBrand(query);
      isLoading.value = false;
    };

    const clearAll = function () {
      this.selectedCountries = []
    };

    const ajaxFindBrand = function(query) {
      let brands = allBrands.value;

      // Normalize the query and remove diacritics
      const normalizedQuery = query.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

      // Filter the brands array for any brand that includes the normalized query (case-insensitive)
      const matchingBrands = brands.filter(brand => {
        // Normalize each brand to remove diacritics
        const normalizedBrand = brand.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return normalizedBrand.includes(normalizedQuery);
      });

      // Return the matching brands
      return matchingBrands;
    };

    async function fetchCars() {
      try {
        const response = await axios.get(window.qsmApiUrl);
        allCars.value = response.data.all.modelDetails;
        allModels.value = response.data.all.models;
        models.value = response.data.all.models;
        allBrands.value = response.data.all.brands;
        allFuelTypes.value = response.data.all.fuelTypes;
        brands.value = [...allBrands.value];
        fuelTypes.value = [...allFuelTypes.value];
        carsToView.value = [...allCars.value];

      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    }

    const updateBrands = () => {
      let filteredModels = allCars.value;

      // Reset Merken als Merk en Brandstof niet geselecteerd zijn.
      if (selectedFuelType.value.length === 0 && selectedBrand.value.length === 0) {
        brands.value = [...new Set(filteredModels.map(model => model.brand))].sort();

        // Reset Model als er geen merk is geselecteerd.
        if (selectedModel.value.length > 0) {
          selectedModel.value = selectedModel.value.filter(selectedModel =>
              models.value.some(model => model.model === selectedModel.model && selectedBrand.value.includes(model.brand))
          );

        }
        // Update carsToView
        carsToView.value = filteredModels;
        return;
      }

      // Update en validate selectedModel
      if (selectedModel.value.length > 0 && selectedBrand.value.length > 0) {
        filteredModels = filteredModels.filter(model =>
            selectedBrand.value.includes(model.brand)
        );
        models.value = [...new Set(filteredModels.map(model => model.model))].sort();

        selectedModel.value = selectedModel.value.filter(model =>
            models.value.includes(model)
        );

        // Update carsToView
        carsToView.value = [...carsToView.value, ...filteredModels];

        return;
      }

      // Reset geselecteerd Model als Merk niet geselecteerd is maar wel Branstof
      if (selectedFuelType.value.length > 0 && selectedBrand.value.length === 0) {

        // Reset Model als er geen merk is geselecteerd.
        if (selectedModel.value.length > 0) {
          selectedModel.value = selectedModel.value.filter(selectedModel =>
              models.value.some(model => model.model === selectedModel.model && selectedBrand.value.includes(model.brand))
          );

        }
      }
      // Filter op merk alleen, indien geselecteerd
      if (selectedBrand.value.length > 0 && selectedFuelType.value.length === 0) {
        filteredModels = filteredModels.filter(model => selectedBrand.value.includes(model.brand));


        // Update beschikbare modellen en valideer geselecteerde modellen
        models.value = [...new Set(filteredModels.map(model => model.model))].sort();

        // Update brandstoftypes gebaseerd op gefilterde modellen
        fuelTypes.value = [...new Set(filteredModels.map(model => model.fuel))].sort();

        // Update carsToView
        carsToView.value = filteredModels;

        return;
      }
      // Filter modellen als Merk en Brandstof zijn geselecteerd
      if (selectedBrand.value.length > 0 && selectedFuelType.value.length > 0) {
        filteredModels = filteredModels.filter(model =>
            selectedBrand.value.includes(model.brand) && selectedFuelType.value.includes(model.fuel)
        );

        // Update beschikbare modellen en valideer geselecteerde modellen
        const updatedModels = [...new Set(filteredModels.map(model => model.model))].sort();
        models.value = updatedModels;

        if (selectedModel.value.length > 0) {
          selectedModel.value = selectedModel.value.filter(model =>
              models.value.includes(model)
          );
        }

        // Update carsToView
        carsToView.value = filteredModels;

      }
    };

    const updateFuelTypes = () => {
      let filteredModels = allCars.value;

      // Reset all values
      if (selectedModel.value.length === 0 && selectedFuelType.value.length === 0 && selectedBrand.value.length === 0) {

        // Reset Brandstof
        fuelTypes.value = [...new Set(filteredModels.map(model => model.fuel))].sort();
        // reset Merken
        brands.value = [...new Set(filteredModels.map(model => model.brand))].sort();
        // Update carsToView naar beginwaarde
        carsToView.value = allCars.value;

        return;
      }

      // Filter op brandstof alleen, indien geselecteerd update Merk en Model
      if (selectedFuelType.value.length > 0 && selectedBrand.value.length === 0) {
        filteredModels = filteredModels.filter(model => selectedFuelType.value.includes(model.fuel));

        // Update de beschikbare merken
        brands.value = [...new Set(filteredModels.map(model => model.brand))];

        // Update beschikbare modellen en valideer geselecteerde modellen
        models.value = [...new Set(filteredModels.map(model => model.model))];

        // Update carsToView
        carsToView.value = filteredModels;

      }

      // Filter Models
      if (selectedFuelType.value.length > 0 && selectedBrand.value.length > 0) {
        filteredModels = filteredModels.filter(model =>
            selectedFuelType.value.includes(model.fuel) && selectedBrand.value.includes(model.brand)
        );

        // Update beschikbare modellen en valideer geselecteerde modellen
        models.value = [...new Set(filteredModels.map(model => model.model))];

        // Update carsToView
        carsToView.value = filteredModels;
      }

    };
    const updateModel = () => {

      // Begin altijd met alle beschikbare auto's
      let filteredModels = allCars.value;

      // Update Brandstof als deze niet is geslecteerd.
      if (selectedModel.value.length > 0 && selectedBrand.value.length > 0  && selectedFuelType.value.length === 0) {
        const selectedModelsByBrand = buildSelectedModelsByBrand(selectedBrand.value, selectedModel.value);

        filteredModels = filteredModels.filter(car => {
          // eslint-disable-next-line no-prototype-builtins
          if (selectedModelsByBrand.hasOwnProperty(car.brand)) {

            return selectedModelsByBrand[car.brand].length === 0 || selectedModelsByBrand[car.brand].includes(car.model);
          }
          return false;
        });

        // Update brandstoftypes gebaseerd op gefilterde merk(en en model(len)
        fuelTypes.value = [...new Set(filteredModels.map(model => model.fuel))].sort();

        carsToView.value = filteredModels;
        return;
      }

      // Update carsToView als Merk, Brandstof en Model is geslecteerd
      if (selectedModel.value.length > 0 && selectedFuelType.value.length > 0 && selectedBrand.value.length > 0) {

        filteredModels = filteredModels.filter(model =>
            selectedBrand.value.includes(model.brand) && selectedModel.value.includes(model.model) && selectedFuelType.value.includes(model.fuel)
        );

        // Update brandstoftypes gebaseerd op gefilterde Merk(en) en Model(len)
        fuelTypes.value = [...new Set(filteredModels.map(model => model.fuel))].sort();
        // Update carsToView
        carsToView.value = filteredModels;

        return;
      }


      // Update Brandstof als alleen merk nog is geselecteerd .
      if (selectedModel.value.length === 0  && selectedBrand.value.length > 0 && selectedFuelType.value.length === 0 ) {
        filteredModels = filteredModels.filter(model => selectedBrand.value.includes(model.brand)
        );

        // Update brandstoftypes gebaseerd op gefilterde Merk(en) en Model(len)
        fuelTypes.value = [...new Set(filteredModels.map(model => model.fuel))].sort();

        // Update carsToView
        carsToView.value = filteredModels;

      }
    };

    const buildSelectedModelsByBrand = (brands, models) => {
      const modelsByBrand = {};
      brands.forEach(brand => {
        const carsForBrand = allCars.value.filter(car => car.brand === brand);

        const modelsForBrand = carsForBrand.filter(car => models.includes(car.model)).map(car => car.model);

        if (modelsForBrand.length > 0) {
          modelsByBrand[brand] = modelsForBrand;
        } else {

          modelsByBrand[brand] = [];
        }
      });

      return modelsByBrand;
    };


    const translateOption = (option) => {
      return translateDict.value[option] || option;
    };

    const encodeForURL = (text) => {
      return encodeURIComponent(text).replace(/%20/g, '+');
    };

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    const validateForm = () => {
      if (selectedModel.value.length === 0 && selectedBrand.value.length === 0 && selectedFuelType.value.length === 0) {
        validation.error = true;
        validation.message = 'U moet tenminste 1 keuze maken';
      } else {
        applyFiltersAndNavigate();
      }
    };

    const applyFiltersAndNavigate = () => {
      const translateFuel = computed(() => {
        return selectedFuelType.value.map(fuelType => translateDict.value[fuelType] || fuelType);
      });

      const encodedModels = selectedModel.value.map(model => encodeForURL(model));
      const encodedBrands = selectedBrand.value.map(brand => encodeForURL(brand));
      const encodedFuelType = translateFuel.value.map(fuel => encodeForURL(fuel));

      const modelQueryParam = encodedModels.join(',');
      const brandQueryParam = encodedBrands.join(',');
      const fuelTypeQueryParam = encodedFuelType.join(',');

      const baseUrl = window.location.origin;

      let path;

      // Check if all query parameters are empty
      if (modelQueryParam === '' && brandQueryParam === '' && fuelTypeQueryParam === '') {
        path = "/aanbod";
      } else {
        // Build the query string only if any filter is applied
        path = "/aanbod/?filter[merk]=" + brandQueryParam +
            "&filter[model]=" + modelQueryParam +
            "&filter[brandstof]=" + fuelTypeQueryParam;
      }

      // Navigate to the constructed path
      window.location.href = baseUrl + path;
    };


    onMounted(() => {
      fetchCars();
      window.addEventListener('resize', handleResize);
      // Set the title for the component
      if (window.qsmTitle && window.qsmTitle.length > 0) {
        title.value = window.qsmTitle;
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    watch(selectedBrand, updateBrands);
    watch(selectedModel, updateModel);
    watch(selectedFuelType, updateFuelTypes);
    watch(windowWidth, (newWidth) => {
      windowWidth.value = newWidth;
    });


    return {
      allCars,
      allModels,
      models,
      brands,
      allBrands,
      fuelTypes,
      allFuelTypes,
      selectedBrand,
      selectedModel,
      selectedFuelType,
      carsToView,
      loading,
      validation,
      layoutClass,
      layoutPaddingClass,
      justifyContentClass,
      totalCars,
      multiselectModelRef,
      multiselectFuelRef,
      multiselectBrandRef,
      translateDict,
      title,
      isLoading,
      fetchCars,
      translateOption,
      validateForm,
      applyFiltersAndNavigate,
      encodeForURL,
      handleResize,
      updateBrands,
      updateFuelTypes,
      updateModel,
      buildSelectedModelsByBrand,
      limitText,
      asyncFind,
      clearAll
    };
  }
}
</script>

